import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { css } from "@emotion/core"
import Img from "gatsby-image"

import Head from "../components/Head"
import GlobalStyles from "../components/GlobalStyles"

export default ({ children, title, description }) => {
  const basics = require("../cms/basics")
  const home = require("../cms/home")
  const pages = require("../configs/pages")
  
  const query = useStaticQuery(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1024, quality: 80 ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  const decorativePhoto = query.images.edges.find(edge => edge.node.relativePath === home.decorative_photo.replace("/assets/", "")).node.childImageSharp.fluid

  return (
    <>
      <Head
        title={title}
        description={description}
      />
      <GlobalStyles />

      {/* outermost div */}
      <div
        css={css`
          display: grid;
          grid-template-columns: minmax(16px, 1fr) minmax(0, 1024px) minmax(16px, 1fr);
        `}
      >
        {/* wrapping div */}
        <div
          css={css`
            margin-top: 24px;
            margin-bottom: 64px;

            grid-column: 2;

            display: grid;
            grid-template-rows: 128px max-content max-content; /* decorative photo, nav, content */
            grid-row-gap: 32px;

            @media (max-width: 768px) {
              grid-template-rows: 64px max-content max-content;
            }
          `}
        >
          <Img
            fluid={decorativePhoto}
            css={css`
              border-radius: 8px;
            `}
          />

          <header
            css={css`
              display: grid;
              grid-template-columns: max-content 1fr;
              grid-column-gap: 32px;
              align-items: start;

              @media (max-width: 768px) {
                grid-template-columns: 1fr;
                grid-template-rows: auto auto;
                grid-row-gap: 16px;
              }
            `}
          >
            <Link
              to="/"
              css={css`
                text-decoration: none;
              `}
            >
              <h1
                css={css`
                  color: var(--text-500);
                  font-size: 1.2rem;
                  margin: 0;
                  letter-spacing: -.04rem;
                `}
              >
                {basics.site_name}
              </h1>
            </Link>
            
            <nav
              css={css`
                @media (max-width: 768px) {
                  margin-left: -3px; /* offset nav on mobile because of highlight padding */
                }
              `}
            >
              {pages.map(page => (
                <Link
                  key={page.link}
                  to={page.link}
                  css={css`
                    padding: 6px 10px;
                    margin-right: 8px;
                    margin-bottom: 6px;
                    display: inline-block;
                    color: var(--text-300);
                    text-decoration: none;
                    border-radius: 4px;
                    font-weight: 600;
                    font-size: .95rem;
                    transition: background-color .2s, color .2s;

                    @media (hover: hover) {
                      :hover {
                        background-color: var(--accent-100);
                        color: var(--accent-500);
                      }
                    }

                    @media (max-width: 768px) {
                      /* smaller touch targets on mobile for condensed spacing */
                      padding: 3px 5px;
                      margin-bottom: 3px;
                    }
                  `}
                  activeStyle={{
                    backgroundColor: "var(--accent-100)",
                    color: "var(--accent-500)"
                  }}
                >
                  {page.label}
                </Link>
              ))}
            </nav>
          </header>

          <main
            css={css`
              margin: 24px 0;

              @media (max-width: 768px) {
                margin-top: 0;
              }
            `}
          >
            {children}
          </main>
        </div>
      </div>
    </>
  )
}