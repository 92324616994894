module.exports = [
  {
    label: "Home",
    link: "/"
  },
  {
    label: "Insurance and Fees",
    link: "/insurance-and-fees/"
  },
  {
    label: "Training",
    link: "/training/"
  },
  {
    label: "Techniques",
    link: "/techniques/"
  },
  {
    label: "Forgiveness",
    link: "/forgiveness/"
  },
  {
    label: "Workshops",
    link: "/workshops/"
  },
  {
    label: "Blog",
    link: "/blog/"
  }
]