module.exports = {
  fonts: {
    "Open Sans": [400, 600, 700]
  },
  vars: {
    "--text-500": "hsl(33, 2%, 10%)",
    "--text-300": "hsl(33, 2%, 35%)",
    "--text-100": "hsl(33, 10%, 90%)",

    "--accent-500": "hsl(33, 100%, 14%)",
    "--accent-100": "hsl(33, 85%, 88%)",

    "--background": "hsl(43, 100%, 98%)"
  }
}