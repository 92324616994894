import React from "react"
import { Helmet } from "react-helmet-async"

export default ({ title:propTitle, description:propDescription }) => {
  const basics = require("../cms/basics.json")

  const title = propTitle ? `${propTitle} - ${basics.site_name}` : basics.site_name
  const description = propDescription || basics.site_description

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      
      <link rel="icon" href="https://emojicdn.elk.sh/☀️" />
    </Helmet>
  )
}