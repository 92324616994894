import React from "react"
import { css, Global } from "@emotion/core"
import { Helmet } from "react-helmet-async"

import "normalize.css"

export default () => {
  const styleConfig = require("../configs/style")


  let fontUrl = "https://fonts.googleapis.com/css?family="

  for(const font in styleConfig.fonts) {
    fontUrl += font.replace(/ /g, "+")
    fontUrl += ":"
    fontUrl += styleConfig.fonts[font].join(",")
    fontUrl += "|"
  }

  fontUrl += "&display=swap"


  let vars = ""

  for(const variable in styleConfig.vars) {
    vars += `${variable}: ${styleConfig.vars[variable]};`
  }

  return (
    <>
      <Global
        styles={css`
          * {
            font-family: "Open Sans", sans-serif;
            -webkit-appearance: none;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            letter-spacing: -.01rem;
          }

          body {
            ${vars}

            background-color: var(--background);
          }

          ::selection {
            background-color: var(--accent-100);
          }
        `}
      />

      <Helmet>
        <link rel="stylesheet" href={fontUrl} />
      </Helmet>
    </>
  )
}